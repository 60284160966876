<template>
    <emc-base-card icon="mdi-timetable" title="Horários das Aulas por Data">
        <template v-slot:card-text>
          <emc-alert-modal :text="message" color="error" textCancel="Fechar" v-bind:show.sync="snackbar" />
          <emc-loader-progress :show="progress" />

            <v-row>
                <v-col cols="5">
                    <v-expansion-panels height="100px" focusable>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          Horário das Turmas
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-card class="mx-auto elevation-0" style="overflow-y: scroll; max-height: 400px;">
                            <SysGridTreeView @selected="selectGrid"/>
                          </v-card>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>

                </v-col>
                <v-col cols="7">
                    <emc-base-card v-if="Object.keys(grid).length > 0" :title="selected.name">
                        <template v-slot:card-text>
                          <SysScheduleDate :show.sync="editDate" :grid="grid" :date.sync="dateSelected" />
                            <emc-base-card>
                                <template v-slot:card-text>
                                    <p v-if="grid.periodicity_type"><strong>Periodicidade:</strong> {{ grid.periodicity_type.name }}</p>
                                    <p><strong>Professor(a):</strong> {{ grid.teacher_name }}</p>
                                    <v-divider></v-divider>
                                    <v-select
                                      v-model="hoursDefault"
                                      :items="hours.filter((h) => { return (grid && grid.group && h.shift_id == grid.group.shift_id) })"
                                      :menu-props="{ maxHeight: '400' }"
                                      :item-text="item => item.start + ' - ' + item.end"
                                      label="Horários"
                                      multiple
                                      chips
                                      hint="Selecione os horários para cadastro"
                                      return-object
                                      persistent-hint
                                    >
                                  
                                    </v-select>
                                    <v-row class="fill-height">
                                      <v-col>
                                        <v-sheet height="64">
                                          <v-toolbar flat class="text-center">
                                            <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
                                              Hoje
                                            </v-btn>
                                            <v-btn fab text small color="grey darken-2" @click="prev">
                                              <v-icon small>mdi-chevron-left</v-icon>
                                            </v-btn>
                                            <v-toolbar-title v-if="$refs.calendar">
                                              {{ $refs.calendar.title }}
                                            </v-toolbar-title>
                                            
                                            <v-spacer></v-spacer>
                                            <v-btn fab text small color="grey darken-2" @click="next">
                                              <v-icon small>mdi-chevron-right</v-icon>
                                            </v-btn>
                                            <v-menu bottom right>
                                              <template v-slot:activator="{ on, attrs }">
                                                <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                                                  <span>{{ typeToLabel[type] }}</span>
                                                  <v-icon right>mdi-menu-down</v-icon>
                                                </v-btn>
                                              </template>
                                              <v-list>
                                                <v-list-item @click="type = 'day'">
                                                  <v-list-item-title>Dia</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="type = 'week'">
                                                  <v-list-item-title>Semana</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="type = 'month'">
                                                  <v-list-item-title>Mês</v-list-item-title>
                                                </v-list-item>
                                              </v-list>
                                            </v-menu>
                                          </v-toolbar>
                                        </v-sheet>
                                        <v-sheet>
                                          <v-alert
                                            color="warning"
                                            dark
                                            icon="mdi-alert"
                                            border="left"
                                            v-if="grid && grid.classes && grid.classes.length == 0"
                                          >
                                            A turma selecionada não possui horário cadastrado.
                                          </v-alert>
                                          <v-calendar 
                                              ref="calendar" 
                                              v-model="focus" 
                                              color="primary" 
                                              :type="type"
                                              :events="events"
                                              @click:date="viewDay"
                                              @click:event="showEvent"
                                              >
                                          </v-calendar>
                                        </v-sheet>
                                      </v-col>
                                    </v-row>

                                    
                                    <SysRegisterScheduleDate 
                                      :model.sync="event"
                                      v-bind:show.sync="showChangeDate"
                                      @onDestroy="destroy"
                                    />
                                </template>
                            </emc-base-card>
                        </template>
                    </emc-base-card>
                </v-col>
            </v-row>
        </template>
    </emc-base-card>
</template>

<script>

import { mapActions } from 'vuex'

export default {
    name: 'ClassScheduleDate',
    data: () => ({
        grid: {},
        selected: [],
        weeks: [],
        hours: [],
        hourDateSelected: [],
        modalDate: false,
        hourDaysGrid: [],
        focus: '',
        type: 'month',
        typeToLabel: {
            month: 'Mês',
            week: 'Semana',
            day: 'Dia'
        },
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        events: [],
        event: [],
        editDate: false,
        dateSelected: '',
        hoursDefault: [],
        progress: false,
        message: '',
        snackbar: false,
        showChangeDate: false
    }),
    methods: {
        ...mapActions('hour', ['ActionFindDayOfWeeks', 'ActionGetHours']),
        ...mapActions('schedule', ['ActionCreateClasse']),
        selectGrid(item){
            this.grid = item.grid;
            this.selected = item;
        },
        getWeeks() {
            this.ActionFindDayOfWeeks()
                .then((res) => {
                    this.weeks = res.data
                })
        },
        getHours() {
            this.ActionGetHours()
                .then((res) => {
                    this.hours = res.data
                })
        },
        prev () {
          this.$refs.calendar.prev()
        },
        next () {
          this.$refs.calendar.next()
        },
        setToday () {
          this.focus = ''
        },
        showEvent({ nativeEvent, event }) {
          if(event.actived && event.periodicity === 'D') {
            this.event = event
            this.showChangeDate = true
          }
        },
        destroy(event){
          
          var removeIndex = this.events.indexOf(event)
          
          // remove object
          this.events.splice(removeIndex, 1);

          this.showChangeDate = false
        },
        viewDay ({ date }) {

          if(this.hoursDefault.length > 0) {

            var params = [];
            this.progress = true

            for (var i = 0; i < this.hoursDefault.length; i++) {

                const item = {
                  name: this.grid.discipline_name,
                  initial: this.grid.discipline_name.substr(0,2),
                  date: date,
                  start: new Date(date + ' ' + this.hoursDefault[i].start),
                  end: new Date(date + ' ' + this.hoursDefault[i].end),
                  timed: true,
                  color: 'green darken-1',
                  work_load_type: this.grid.work_load_type.name,
                  grid_id: this.grid.id,
                  actived: true,
                  periodicity: this.grid.periodicity_type.initials,
                  class_schedule_id: null
              }

              var day = new Date(date);

              const paramItem = {
                  grid_id: this.grid.id,
                  work_time_id: this.grid.work_time_id,
                  day_of_week_id: (day.getDay() + 2 === 8) ? 1 : day.getDay() + 2,
                  hour_id: this.hoursDefault[i].id,
                  teacher_id: this.grid.teacher_id,
                  start_date: date,
                  end_date: date,
                  ambience_id: this.grid.ambience_id,
                  locked: 0
              }
              
              this.ActionCreateClasse(paramItem)
              .then((r) => {
                  this.grid.classes = r.data
                  item.class_schedule_id = r.data.id
                  this.events.push(item)
                  if(i == this.hoursDefault.length) {
                    this.progress = false
                  }
              })
              .catch((error) => {
                this.progress = false
                if(error.errors && error.errors.class_schedule){
                  this.message = error.errors.class_schedule;
                }else if(error.errors && error.errors.availability){
                  this.message = error.errors.availability;
                }else if(error.message){
                  this.message = error.message;
                }else{
                  this.message = 'Erro ao salvar a aula!';
                }
                this.snackbar = true; 
                i = this.hoursDefault.length
              })

            }

          }

      },
    },
    created() {
        this.getWeeks()
        this.getHours()
    },
    watch: {
        grid() {
            const grid = this.grid
            this.type = 'month'
            var events = []
            var grids = this.selected.group.grids.filter((g) => { return g.work_load_type.has_time_table == 1})
            var start = ''
            var end = ''
            grids.forEach(function(g){
                
              if(!g.classes){
                return;
              }  
              
              g.classes.forEach((c) => {
                  c.days.forEach((d, index) => {
                    
                    if(index === 0) {
                      start = new Date(d + ' ' + c.hour.start)
                    }

                    end = new Date(d + ' ' + c.hour.end)

                      const item = {
                          name: g.discipline_name,
                          initial: g.discipline_name.substr(0,2),
                          date: d,
                          start: new Date(d + ' ' + c.hour.start),
                          end: new Date(d + ' ' + c.hour.end),
                          timed: true,
                          color: (g.id === grid.id) ? 'green darken-1' : 'grey lighten-1',
                          work_load_type: g.work_load_type.name,
                          grid_id: g.id,
                          actived: (g.id === grid.id) ? true : false,
                          periodicity: g.periodicity_type.initials,
                          class_schedule_id: (g.periodicity_type.initials === 'D') ? c.id : null
                      }

                    events.push(item)
                  })
                })
            })

            if(start < new Date() && new Date < end){
              this.focus = new Date()
            } else {
              this.focus = start
            }

            this.events = events

            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        }
    },
}
</script>